<template>
  <footer
    class="footer white-bg pos-r o-hidden bg-contain"
    data-bg-img="images/pattern/01.png"
  >
    <div class="round-p-animation"></div>
    <div class="primary-footer">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-lg-4">
            <div
              class="ht-theme-info bg-contain bg-pos-r h-100 dark-bg text-white"
              data-bg-img="images/bg/02.png"
            >
              <div class="footer-logo">
                <router-link to="/" class="logo text-logo text-white">
                  TRADESK
                </router-link>
              </div>
              <p class="mb-3">
                At TRADESK, we strive to provide innovative customer-specific
                soft solutions around your business through 360 degrees customer
                focused engagement solutions ranging from, emails, SMSs, Short
                Codes, USSD and Call center solutions as well as Social media
                platforms.
              </p>
              <router-link class="btn-simple" :to="{ name: 'about-us' }"
                ><span
                  >Read More <i class="fas fa-long-arrow-alt-right"></i></span
              ></router-link>
              <div class="social-icons social-border circle social-hover mt-5">
                <h4 class="title">Follow Us</h4>
                <ul class="list-inline">
                  <li class="social-facebook">
                    <a href="#"><i class="fa fa-facebook-f"></i></a>
                  </li>
                  <li class="social-twitter">
                    <a href="#"><i class="fa fa-twitter"></i></a>
                  </li>
                  <li class="social-gplus">
                    <a href="#"><i class="fa fa-google-plus"></i></a>
                  </li>
                  <li class="social-linkedin">
                    <a href="#"><i class="fa fa-linkedin"></i></a>
                  </li>
                  <li class="social-skype">
                    <a href="#"><i class="fa fa-skype"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-8 py-8 md-px-5">
            <div class="row">
              <div class="col-lg-6 col-md-6 footer-list">
                <h4 class="title">Useful Links</h4>
                <div class="row">
                  <div class="col-sm-5">
                    <ul class="list-unstyled">
                      <li>
                        <router-link :to="{ name: 'about-us' }"
                          >About Us</router-link
                        >
                      </li>
                      <li>
                        <router-link :to="{ name: 'products.sms' }"
                          >Products</router-link
                        >
                      </li>
                      <li>
                        <router-link :to="{ name: 'faqs' }">FAQs</router-link>
                      </li>
                      <li>
                        <a :href="developer_docs_url" target="_blank"
                          >Developers</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="col-sm-7">
                    <ul class="list-unstyled">
                      <li>
                        <router-link :to="{ name: 'news.index' }"
                          >News</router-link
                        >
                      </li>
                      <li>
                        <a
                          href="http://api.tradesk.co.ke/documents/privacy-policy.pdf"
                          target="_blank"
                          >Privacy Policy</a
                        >
                      </li>
                      <li>
                        <a
                          href="http://api.tradesk.co.ke/documents/terms-conditions.pdf"
                          target="_blank"
                          >Terms & Condition</a
                        >
                      </li>
                      <li>
                        <router-link :to="{ name: 'contact-us' }"
                          >Contact Us</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 sm-mt-5">
                <h4 class="title">Contact us</h4>
                <ul class="media-icon list-unstyled">
                  <li>
                    <p>Ground Floor, Utalii House, Utalii Street, Nairobi, Kenya.</p>
                    <p>PO Box 4058, 00506, Nairobi</p>
                  </li>
                  <li>
                    <a href="mailto:support@tradesk.co.ke"
                      >support@tradesk.co.ke</a
                    >
                  </li>
                  <li><a href="tel:+254718844831">+254 718 844 831</a></li>
                </ul>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-lg-10 col-md-12 mr-auto">
                <div
                  class="align-items-center white-bg box-shadow px-3 py-3 radius d-md-flex justify-content-between"
                >
                  <h4 class="mb-0">NewsLetter</h4>
                  <div class="subscribe-form sm-mt-2">
                    <form id="mc-form" class="group">
                      <input
                        id="mc-email"
                        type="email"
                        value=""
                        name="EMAIL"
                        class="email"
                        placeholder="Email Address"
                        required=""
                      />
                      <input
                        class="btn btn-theme"
                        type="submit"
                        name="subscribe"
                        value="Subscribe"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="secondary-footer">
      <div class="container">
        <div class="copyright">
          <div class="row align-items-center">
            <div class="col-md-6">
              <span>Copyright 2021 | All Rights Reserved</span>
            </div>
            <div class="col-md-6 text-md-right sm-mt-2">
              <span>A product of <a href="#">Futurefast</a></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    developer_docs_url() {
      return process.env.VUE_APP_DEVELOPER_DOCS_URL;
    },
  },
};
</script>
